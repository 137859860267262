import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../contexts/AppContext/AppContext';
import pyodide from 'pyodide';
import { Grid, Card, Button } from "@nextui-org/react";
import { useMediaQuery } from '../utils/useMediaQuery.js'
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';

import Logo from '../components/Logo/Logo';
import SearchBar from '../components/SearchBar/SearchBar';

import LanguageSelect from '../components/LanguageSelect/LanguageSelect';
import Footer from '../components/Footer/Footer';
//import Proposals from '../components/Proposals/Proposals';
//import ModalTabs from '../components/ModalTabs/ModalTabs';

function Home() {
  const {
    searchTerm,
    gooptSearch,
    setSearchTerm,
    imFeelingLucky,
  } = useContext(AppContext);

  const handleChange = (term) => {
    setSearchTerm(term);
    handleKeyDown(term)
  }

  const handleKeyDown = (term) => {
    imFeelingLucky(term);
  };

  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', files[0]);



    try {
      const response = await fetch('https://gpt-backend-app.herokuapp.com/decksummary', {
        method: 'POST',
        body: formData
      });

      if (!response?.ok) {
        throw new Error('Failed to get response from the server');
        setFiles([]);
      }

      const result = await response?.json();
      console.log(result);
      setResult(result?.response?.replace(/\n/g, '<br>'));
      setIsLoading(false);
      setFiles([]);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setMessage("Failed to get response from the server");
      setFiles([]);
    }
  };



  const handleDrop = (e) => {
    e.preventDefault();
    setMessage("");
    const file = e?.dataTransfer?.files[0];

    if (file?.type !== 'application/pdf') {
      setMessage("Only PDF files are accepted");
      return;
    }

    setFiles([file]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const removeFile = () => {
    setFiles([]);
  };

  const isMd = useMediaQuery(960);

  return (
    <div className="w-full h-screen">
      <header className="flex items-center content-center w-full px-6 py-2 border border-googray-light">
        <a href="/">
          <Logo className="text-3xl mr-14" />
        </a>
      </header>

      <main className="flex flex-col items-center justify-center w-full flex-grow space-y-7" style={{ minHeight: 'calc(100vh - 94px)' }}>
        <h2 className="mt-8 px-4 text-2xl font-bold leading-tight text-gray-900 sm:text-3xl md:text-4xl lg:text-5xl xl:text-4xl">
          Pitch Deck Summary powered by DAOhub & OpenAI
        </h2>

        <p class="mt-8 px-4 text-lg font-medium text-left leading-tight text-gray-900 sm:text-lg md:text-lg lg:text-lg xl:text-lg xl:w-1/2 sm:w-1/3">
          <ul class="list-disc">
            <li>This is a demo of DAOhub's AI model developed for investment DAOs.</li>
            <li>For demo purposes, you can only upload one document at a time. Although the LLM models we use provide consistent and accurate information, we cannot guarantee 100% accuracy.</li>
            <li>To use this tool, upload the pitch deck by dragging the file or clicking on the box below. Click on the summarize button and wait for 5-15 seconds. Note that it only supports PDF files at this time.</li>
            <li>The information provided on this page is not investment advice.</li>
            <li>When you upload a document, we use encryption methods to securely process it and remove the file afterwards. Please note that we are using external Large Language Models such as OpenAI and cannot provide any information about their encryption methods. By uploading a file, you acknowledge that you understand and agree to these terms.</li>
            <li>If you're interested in using the full version of this tool, please contact Arda at arda@mefi.ca.</li>
            <li><strong>Please don't share this link with anyone outside of your organization.</strong></li>
          </ul>
        </p>



        <div className="w-full place-items-center text-left xl:w-1/2 sm:w-1/3" style={{ marginBottom: '100px' }}>
          <div className="p-4 border border-gray-200 rounded-md">
            <h3 className="mb-2 text-lg font-medium">Upload the Pitch Deck (PDF only)</h3>
            <div
  className="w-full h-40 bg-gray-100 border border-dashed border-gray-400 rounded md flex justify-center items-center"
  onDrop={handleDrop}
  onDragOver={handleDragOver}
>
  <input type="file" onChange={(e) => setFiles([e?.target?.files[0]])} className="hidden" id="fileInput" accept="application/pdf" />
  {files?.length > 0 ? (
    <div className="mt-2 flex items-center justify-between">
      <div className="flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
        <div className="flex flex-col">
          <span className="text-sm font-medium">{files[0]?.name}</span>
          <span className="text-xs text-gray-500">{(files[0]?.size / (1024 * 1024)).toFixed(2)} MB</span>
        </div>
      </div>
      <button className="text-xs text-red-600 font-medium" onClick={removeFile}>Remove</button>
    </div>
  ) : (
    <label htmlFor="fileInput" className="text-sm text-gray-500 cursor-pointer">
      Drag and drop a PDF here or click to choose a file
    </label>
  )}
  {message && <span className="text-sm text-red-600">{message}</span>}
  {isLoading && (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-75">
      <div className="inline-block animate-spin ease duration-300 w-10 h-10 border-2 border-t-4 border-gray-900 rounded-full"></div>
    </div>
  )}
</div>
<div className="mt-4">
<button className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 cursor-pointer" onClick={handleSubmit}>
Summarize
</button>
</div>
{result && <div className="mt-4 text-md text-gray-1000" dangerouslySetInnerHTML={{ __html: result }}></div>}
</div>
</div>
</main>
<Footer />
</div>
);
}

export default Home;
