import React from 'react';

function Logo({ className = '' }) {
  return (
    <div className={ 'max-w-[150px] font-vollkorn ' + className }>
      <img src="https://www.daohub.xyz/img/logo.png" alt="DAO logo" width="130" height="250"/>
    </div>

  );
}

export default Logo;
