import React, { useContext, useEffect, useState } from 'react';

import { AppContext } from '../contexts/AppContext/AppContext';

import { Card } from "@nextui-org/react";


import LoadingArticle from '../components/LoadingArticle/LoadingArticle';
import ReadMore from '../components/ReadMore/ReadMore';
import SearchHeader from '../components/SearchHeader/SearchHeader';
import Footer from '../components/Footer/Footer';

function Site() {
  const {
    searchResults,
    searchTerm,
    generateArticleText,
    isLoadingResults,
    isLoadingArticle,
    getArticleTemplate
  } = useContext(AppContext);

  const [queryParams, setQueryParams]  = useState(new URLSearchParams(window.location.search))
  const [title, setTitle] = useState(queryParams.get('title'));
  const [initialText, setInitialText]  = useState(queryParams.get('text'));
  const [text, setText] = useState(initialText);




  useEffect(() => {
    setQueryParams(new URLSearchParams(window.location.search))
    setTitle(queryParams.get('title'))
    setInitialText(queryParams.get('text'))
    setText(initialText)



  }, [window.location.search]);



  //const queryParams = new URLSearchParams(window.location.search);

useEffect(() => {
  initArticle();
}, [queryParams]);

useEffect(() => {
  initArticle();
});


  const initArticle = () => {
    if (searchResults) {
      setTitle(searchTerm);
      setText(searchResults);
    }

    //if (title && text?.length < 800) {
      //loadArticle();
    //}
  };

  const loadArticle = async () => {
    let articleText = text;

    //let articleNewText = await generateArticleText(articleText);

    //setText(articleText + articleNewText);
    setText(articleText);
  };


  const formatArticleText = (text) => {
    //let paragraphs = text.slice(text.indexOf('"""') + 4).split('\n');
    let paragraphs = text[0];



    let formattedText = () => {
      return (
        <p className="mb-4">
          { paragraphs }
        </p>
      )
    };

    return (
      <div>
        { text[0] }
      </div>
    );
  };


  return (
    <div className="w-full h-screen">
      <SearchHeader />
      <div className="flex justify-center py-6 sm:py-12">
  <div className="w-full sm:w-10/12 md:w-8/12 lg:w-6/12 text-left px-4 sm:px-0">
    <h1 className="mb-6 text-lg sm:text-xl font-montserrat">
      { title }
    </h1>
    <Card isHoverable={true} variant="flat" css={{ mw: "800px", p:'15px' }}>
      <Card.Body>
        <p className="text-lg font-light tracking-normal sm:text-lg">
          { text !== initialText &&
            formatArticleText(text)
          }

          { (isLoadingArticle || !text) &&
            <LoadingArticle />
          }
        </p>

        {/*
        { (!isLoadingArticle && title) &&
          <ReadMore onClick={ loadArticle } />
        */}

      </Card.Body>
    </Card>
  </div>
</div>

  <Footer/>



    </div>

  );
}

export default Site;
