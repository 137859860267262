import React, { useState, useEffect } from 'react';
import { GetDaoProposals, summarize_proposal, summarize_discussion } from '../utils/proposalServices.js';
import { Dialog, Transition } from '@headlessui/react';
import { Pagination, Badge } from '@nextui-org/react';
import SearchHeader from '../components/SearchHeader/SearchHeader';

import Logo from '../components/Logo/Logo';
import Footer from '../components/Footer/Footer';

function ActiveProposals() {
  const [selectedProposal, setSelectedProposal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isProposalOpen, setIsProposalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const pageSize = 5;
  const [isFetching, setIsFetching] = useState(true);
  const [proposalsToDisplay, setProposalsToDisplay] = useState();


  const [proposals, setProposals] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await GetDaoProposals();
      const fetchedProposals = data?.data?.data?.proposals;

      if (fetchedProposals) {
        const updatedProposals = [];

        for (let i = 0; i < fetchedProposals.length; i++) {
          const proposal = fetchedProposals[i];
          const discussion_url = proposal?.discussion;
          const proposal_summary = await summarize_proposal(discussion_url);

          updatedProposals.push({
            ...proposal,
            proposal_summary: proposal_summary,
          });
        }

        const filteredProposals = updatedProposals.filter(
          (proposal) => proposal.discussion // only keep proposals with a non-empty discussion field
        );

        setProposals(filteredProposals);
      }
    };

    fetchData();
  }, []);

console.log(proposals)


  const openProposal = async (proposal) => {
    setSelectedProposal(proposal);
    setIsLoading(true);
    setIsProposalOpen(true);

    const discussion_summary = await summarize_discussion(proposal.discussion);
    setSelectedProposal((prevProposal) => ({
      ...prevProposal,
      discussion_summary: discussion_summary,
    }));

    setIsLoading(false);
  };

  const closeProposal = () => {
    setSelectedProposal(null);
    setIsProposalOpen(false);
  };



  const pageCount = Math.ceil(proposals.length / pageSize);

  useEffect(() => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setProposalsToDisplay(proposals.slice(startIndex, endIndex))


      }, [proposals]);

  useEffect(() => {

    if (proposalsToDisplay?.length > 1) {

        setIsFetching(false)
      }

  }, [proposalsToDisplay]);


    return (
      <div className="w-full h-screen">
        <SearchHeader />

        <main className="flex flex-col items-center justify-center w-full flex-grow space-y-7" style={{ minHeight: 'calc(100vh - 94px)' }}>
          <h2 className="mt-8 px-4 text-2xl font-bold leading-tight text-gray-900 sm:text-3xl md:text-4xl lg:text-5xl xl:text-4xl">
            Recent Proposals
          </h2>
          <div className="w-full grid grid-cols-1 gap-4 p-4 place-items-center text-left" style={{ marginBottom: '94px' }}>
          {isFetching ? (
<>
  <div className="animate-pulse w-full max-w-3xl bg-white shadow-md rounded-lg p-6 cursor-pointer transform transition-all duration-200 hover:shadow-xl hover:-translate-y-1 focus:ring-2 focus:ring-blue-400 focus:outline-none relative">
    <div className="h-4 bg-gray-300 rounded w-4/4 mb-2"></div>
    <div className="h-4 bg-gray-300 rounded w-4/4 mb-2"></div>
    <div className="h-4 bg-gray-300 rounded w-4/4 mb-2"></div>
  </div>

  <div className="animate-pulse w-full max-w-3xl bg-white shadow-md rounded-lg p-6 cursor-pointer transform transition-all duration-200 hover:shadow-xl hover:-translate-y-1 focus:ring-2 focus:ring-blue-400 focus:outline-none relative">
    <div className="h-4 bg-gray-300 rounded w-4/4 mb-2"></div>
    <div className="h-4 bg-gray-300 rounded w-4/4 mb-2"></div>
    <div className="h-4 bg-gray-300 rounded w-4/4 mb-2"></div>
  </div>


  <div className="animate-pulse w-full max-w-3xl bg-white shadow-md rounded-lg p-6 cursor-pointer transform transition-all duration-200 hover:shadow-xl hover:-translate-y-1 focus:ring-2 focus:ring-blue-400 focus:outline-none relative">
    <div className="h-4 bg-gray-300 rounded w-4/4 mb-2"></div>
    <div className="h-4 bg-gray-300 rounded w-4/4 mb-2"></div>
    <div className="h-4 bg-gray-300 rounded w-4/4 mb-2"></div>
  </div>

  <div className="animate-pulse w-full max-w-3xl bg-white shadow-md rounded-lg p-6 cursor-pointer transform transition-all duration-200 hover:shadow-xl hover:-translate-y-1 focus:ring-2 focus:ring-blue-400 focus:outline-none relative">
    <div className="h-4 bg-gray-300 rounded w-4/4 mb-2"></div>
    <div className="h-4 bg-gray-300 rounded w-4/4 mb-2"></div>
    <div className="h-4 bg-gray-300 rounded w-4/4 mb-2"></div>
  </div>

  <div className="animate-pulse w-full max-w-3xl bg-white shadow-md rounded-lg p-6 cursor-pointer transform transition-all duration-200 hover:shadow-xl hover:-translate-y-1 focus:ring-2 focus:ring-blue-400 focus:outline-none relative">
    <div className="h-4 bg-gray-300 rounded w-4/4 mb-2"></div>
    <div className="h-4 bg-gray-300 rounded w-4/4 mb-2"></div>
    <div className="h-4 bg-gray-300 rounded w-4/4 mb-2"></div>
  </div>

</>
) : (
  proposalsToDisplay.map((proposal) => (
  <div
    key={proposal.id}
    className="w-full max-w-3xl bg-white shadow-md rounded-lg p-8 cursor-pointer transform transition-all duration-200 hover:shadow-xl hover:-translate-y-1 focus:ring-2 focus:ring-blue-400 focus:outline-none relative mb-4 sm:mb-8"
    onClick={() =>
      selectedProposal?.id === proposal.id
        ? setSelectedProposal(null)
        : openProposal(proposal)
    }
    tabIndex={0}
  >
    <div className="absolute top-2 right-2">
    {
      proposal?.state === 'active' ?
      (
        <Badge color="success" className="text-right p-1 hidde">
          Active
        </Badge>

      ) : (
        <Badge color="error" className="text-right p-1 hidde">
          Closed
        </Badge>

      )
    }

    </div>
    <div>
  <h3 className="text-xl font-semibold mb-2">
          {proposal.title}
      </h3>
    </div>

    <p className="text-gray-600">
      {selectedProposal?.id === proposal.id
        ? proposal.proposal_summary
        : `${proposal.proposal_summary?.split('. ')[0]}...`}
    </p>
    {selectedProposal?.id === proposal.id && (
  <div className="w-full max-w-3xl bg-white rounded-lg p-6 cursor-pointer transform transition-all duration-200 focus:ring-2 focus:ring-blue-400 focus:outline-none">
    {selectedProposal?.discussion_summary ? (
      <>
        <h2 className="text-xl font-semibold mb-2">
          Summary of the discussion
        </h2>
        <div
          className={`${
            isProposalOpen ? '' : 'max-h-0'
          } overflow-hidden transition-all duration-300 mb-4 overflow-y-auto`}
        >
          <p className="text-gray-600">
            {selectedProposal?.discussion_summary}
          </p>
        </div>
      </>
    ) : (
      <div className="animate-pulse">
        <div className="h-4 bg-gray-300 rounded w-3/4 mb-2"></div>
        <div className="h-4 bg-gray-300 rounded w-3/4 mb-2"></div>
        <div className="h-4 bg-gray-300 rounded w-3/4 mb-2"></div>
      </div>
    )}
    {selectedProposal?.discussion_summary && (
      <button
        className="mb-2 text-sm text-blue-600 next-btn-outlined next-btn-sm flex mt-5 right-4 bottom-4"
        onClick={() => window.open(proposal.discussion)}
      >
        View the Discussion
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 inline ml-1 mb-1"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M17 8l4 4m0 0l-4 4m4-4H3"
          />
        </svg>
      </button>
    )}
  </div>
)}
</div>

))

)}
{isLoading && (
  <div className="animate-pulse">
    <div className="h-10 bg-gray-300 rounded w-full mb-2"></div>
    <div className="h-10 bg-gray-300 rounded w-full mb-2"></div>
  </div>
)}
</div>
{pageCount > 1 && (
<Pagination
  count={pageCount}
  active={page}
  onChange={(newPage) => setPage(newPage)}
  size="large"
  margin={2}
/>
)}
</main>

  <Footer />
</div>
)
}
export default ActiveProposals
