import './App.css';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import { NextUIProvider } from '@nextui-org/react';

import { AppContextProvider } from './contexts/AppContext/AppContext';

import Home from './pages/Home';
import Results from './pages/Results';
import Site from './pages/Site';
import ActiveProposals from './pages/Governance';
import { inject } from '@vercel/analytics';

function App() {
  inject();
  return (
    <div className="App">

        <BrowserRouter>
          <AppContextProvider>
            <NextUIProvider>
              <Routes>
                <Route exact path="/results" element={ <Results /> } />
                <Route path="/site" element={ <Site /> } />
                <Route exact path="/" element={ <Home /> } />
              </Routes>
            </NextUIProvider>
          </AppContextProvider>
        </BrowserRouter>

    </div>
  );
}

export default App;
